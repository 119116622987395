export const AUTHENTICATION_TRANSLATIONS_IT = {
    authentication: {
        login: {
            description: `Accedi alla nostra wine platform.`,
            emailInput: `Email`,
            passwordInput: `Password`,
            googleLogin: `Entra con Google`,
            appleLogin: `Entra con Apple`,
            otherwise: `Oppure`,
            forgotPasswordText: `Password dimenticata?`,
            loginButton: `Login`,
            register: `Se non hai un account <a class="text-underline" href="authentication/registration">Registrati</a>`,
        },
        register: {
            description: `Non usare una mail generica perché con queste credenziali potrai entrare in tutto l’ecosistema Albicchiere,
            sia a casa che a lavoro`,
            nameInput: `Nome`,
            lastnameInput: `Cognome`,
            emailInput: `Email`,
            passwordInput: `Password`,
            passwordMessage: `This field is required\n 
            Password must have:\n 
             - at least 1 lower case letter\n 
             - at least 1 upper case letter\n 
             - at least 1 number\n 
             - at least 1 special character (^ $ * . [ ] { } ( ) ? ! @ # % & / \ , > \< ’ : ; | _ ~ \` + - ) `,
            confirmPassword: `Conferma Password`,
            termsAndContitionText: `Continuando, accetti <a href="https://albicchiere.com/terms-of-service" target="_blank">termini e
                condizioni</a> di albicchiere e confermi di aver letto l' <a
                href="https://albicchiere.com/privacy-policy" target="_blank">informativa sulla privacy</a>.`,
            loginButton: `Login`,
            registerButton: `Registrati`,
        },
        passwordReset: {
            emailStep: {
                description: `Inserisci il tuo indirizzo email per recuperare le tue credenziali.`,
                email: `Email`,
                sendEmailButton: `Invia Email`,
                registerButton: `Registrati`,
            },
            resetStep: {
                description: `Inserisci il tuo indirizzo email per recuperare le tue credenziali.`,
                otpInput: `Codice OTP`,
                newPassword: `Nuova password`,
                confirmPassword: `Conferma nuova password`,
                passwordText: `La password deve essere almeno di 8 caratteri, inoltre deve contenere almeno un numero e un
                    carattere speciale`,
                savePasswordButton: `Salva nuova password`,
            },
        },
    }
}