export const COMPONENTS_TRANSLATIONS_EN = {
    components: {
        addressFormComponent: {
            nameInput: {
                title: 'Location Name *',
                description: "Enter a name to identify the address.",
            },
            addressCheckbox: {
                shippingText: 'Save as shipping address',
                billingText: 'Save as billing address',
            },
            addressInput: {
                title: 'Address *',
                description: "Enter the address of your company",
                placeholder: 'Street and house number',
            },
            cityInput: {
                placeholder: 'City',
            },
            stateInput: {
                placeholder: 'Province',
            },
            countryInput: {
                placeholder: 'Country',
            },
            postalCodeInput: {
                placeholder: 'Postal code',
            },
        },
        appTopbar: {
            searchbarPlaceholder: `Search`,
            changeCompany: {
                title: `Change Company`,
                description: `Select the company you want to work for. If you have access to
                            individual locations, select the one you want to operate in.`,
                button: `Connect to a new company`,
            },
            unchangeableCompany: {
                title: `Change Company`,
                description: `You cannot change Access during a creation, modification or removal flow.
                        Exit the flow or go back to the home to change Access`,
            }
        },
        appPageLayout: {
            staticMessage: {
                handleNewConnection: `Click here to manage new connection requests to your company`,
                deleteRequestMessage: `You have requested to be deleted`,
                pendingDocumentsMessage: `You are in limited mode, check the documents to start operating as the company's Admin!`,
            },
            feedbackButton: `Send feedback`,
            sentryFeedback: {
                nameLabel: `Name`,
                namePlaceholder: `Your name`,
                emailLabel: `Email`,
                emailPlaceholder: `your.email@example.com`,
                messageLabel: `Description`,
                messagePlaceholder: `What error did you encounter? what did you expect to happen?`,
                isRequiredLabel: `(required field)`,
                triggerLabel: "Send feedback",
                formTitle: "Leave feedback",
                submitButtonLabel: "Send feedback",
                cancelButtonLabel: "Cancel",
                addScreenshotButtonLabel: "Add a Screenshot",
                removeScreenshotButtonLabel: "Remove Screenshot",
            },
        },
        companyCreationForm: {
            typeInput: {
                title: 'Company Type',
                description: `Select the type of your company. Select “winery” only if
                        you are a direct wine producer.
                        Resellers, importers, distributors or users of Albicchiere services must
                        select the “professional” option.`,
                wineryBox: {
                    title: 'Winery',
                    description: 'Select Winery if your company is a producing winery.',
                },
                profesionalBox: {
                    title: 'Professional',
                    description: 'Select Professional if your company provides services.',
                }
            },
            imageInput: {
                title: `Company logo`,
                description: `Upload your brand logo.`,
            },
            nameInput: {
                companyTitle: 'Company name *',
                wineryTitle: 'Winery name *',
                description: `This is the name by which your winery is known in the world and
                        will appear in your
                        labels`,
            },
            emailInput: {
                title: `Email *`,
                description: `Enter the reference company email address`,
            },
            fullNameInput: {
                title: `Company name *`,
                description: `Enter the company name of your company. This name will be used
                        as the producer.`,
            },
            vatNumberInput: {
                title: `VAT number *`,
                description: `Enter the VAT number of your company, including the country code
                        (for example IT00000000000)`,
            },
            websiteInput: {
                title: `Website`,
                description: `Enter the URL of your website`,
            },
            phoneInput: {
                title: `Phone *`,
                description: `Enter your company phone number.`,
            },
        },
        impressumBox: {
            description: `Description`,
            nation: `Nation`,
            inputPlaceholder: `Operator name, address`,
        },
        wineCreationForm: {
            title: `Wine Data`,
            description: `Enter the details of the wine you want to create. 
            Enrich the details of your wine, give your users as much information as possible 
            to make the experience of each of your wines unique and immersive.`,
            nameInput: {
                title: `Name *`,
                description: `Enter the name of your wine, excluding the vintage.`,
            },
            colorInput: {
                title: `Color *`,
                description: `Enter the color of your wine.`,
            },
            styleInput: {
                title: `Style`,
                description: `Enter the type of wine. If it is a still wine, leave this field blank.`,
            },
            regionInput: {
                title: `Origin *`,
                description: `Select the wine region of origin of your wine. If you cannot find it, send us feedback
                        to
                        add it.`,
            },
            grapeInput: {
                title: `Grapes`,
                description: `Select all the grape varieties that make up your wine. We suggest ordering them by the largest
                        quantity.`,
            },
            selectedWineView: {
                title: `Wine data`,
                name: `Name`,
                color: `Color`,
                region: `Origin`,
                grapes: `Grapes`,
            }
        },
        vintageCreationForm: {
            title: `Vintage Data`,
            description: `Now enter the specific characteristics of the vintage`,
            year: {
                title: `Vintage *`,
                description: `Enter the vintage of the wine or select the NV button for non-vintage wines.
                            The “Special Edition” field is mandatory for all NV wines and for wines in which you already have
                            a
                            pre-existing vintage. Enter a text to differentiate it and make it searchable by consumers
                            (eg:
                            special edition, xmas box, etc).`,
                vintageOption: {
                    title: `Vintage`,
                    description: `For vintage wines`,
                    vintagePlaceholder: `Select vintage`,
                },
                nvOption: {
                    title: `N.V.`,
                    description: `For non-vintage wines`,
                },
                yearDetailPlaceholder: `Special Edition`,
            },
            images: {
                title: `Images`,
                description: `Upload the image of the label and the bottle of your wine. Follow the formats or the ratio
                        indicated. The uploaded images will be viewed by consumers so we suggest you
                        follow the instructions below.`,
                labelImage: {
                    title: `Label`,
                    description: `The size must be 480px x 640px`,
                },
                bottleImage: {
                    title: `Bottle`,
                    description: `The image must have a height of 960px`,
                },
            },
            descriptionInput: {
                title: `Description`,
                description: `Enter the description of your wine to give more information about the processes
                            of
                            production, aromas and history of your product. Select the language for which you want to
                            create your description at the top.`,
                langPlaceholder: `Language`,
            },
            alcohol: {
                title: `Alcohol content *`,
                description: `Enter the correct alcohol content to automate the calculation
                        of energy values.`,
            },
            temperature: {
                title: `Serving temperature *`,
                description: `Indicate the temperature at which your wine should be served. Guarantee
                        a perfect tasting experience for your consumers.`,
            },
            primaryPairings: {
                title: `Perfect pairings`,
                description: `Enter up to 3 foods that you think are perfect to accompany your wine. They will appear among the
                        suggested ones.`,
            },
            secondaryPairings: {
                title: `Excellent pairings`,
                description: `Enter up to 6 foods that pair well with your wine but will not appear among the suggested ones.`,
            },
            mood: {
                title: `Mood`,
                description: `Select the ideal moods for tasting your wine.`,
            },
            philosophy: {
                title: `Philosophy`,
                description: `Indicate which production philosophy was used to make your vintage.`,
            },
            allergen: {
                title: `Allergens`,
                description: `Select the allergens that are part of the vintage`,
            },
            awards: {
                title: `Awards`,
                description: `Enter the awards received by this vintage and press enter to save the typed text.`,
            },
            cropLabelPanel: {
                title: `Label Image Size Error`,
                description: `The uploaded image does not meet the required dimensions. You need to crop it before proceeding.`,
            },
            cropBottlePanel: {
                title: `Bottle Image Size Error`,
                description: `The uploaded image does not meet the required dimensions. You need to crop it before proceeding.`,
            }

        },
        userRoleForm: {
            access: {
                title: `Access`,
                description: `Select company if you want to assign a company-wide role to your
            user. Instead, select
            location if you want to assign a different role to your user for each location of your company.`,
                inviteUserDescription: `You are inviting a new user to your company. Enter his email and select a role, then press send
                invitation.`,
                locationOption: `Location`,
                companyOption: `Company`,
            },
            role: {
                title: `Role`,
                locationRoleDescription: `Select the location and the relevant role you intend to assign to the user. Save to continue.`,
                companyRoleDescription: `Select the role and save to continue.`,
                addLocationButton: `Add Location`,
            }
        }
    },
}