<ng-container *ngIf="{
    showEnterpriseCompanyAccessPanel:showEnterpriseCompanyAccessPanel$ | async,
    user: user$ | async,
    companyHasPendingUsers: companyHasPendingUsers$ | async,
    isSidebarReduced:isSidebarReduced$ | async,
    isMobileView: isMobileView$ | async,
    selectedUserAccess:selectedUserAccess$ | async
} as vm">
    <div class="albi-dashboard">
        <nav class="menu-left-panel">
            <albi-vertical-tabs class="custom-borders" [tabs]="verticalTabs" [releaseNumber]="RELEASE_NUMBER"
                (changeReduced)="changeIsSidebarReduced($event)" (changeTheme)="onChangeTheme($event)"
                [resizeWidth]="vm.isSidebarReduced?'reduced':'normal'">
                <albi-button [buttonColor]="AlbiButtonColorEnum.BLACK" buttonVariant="textOnly"
                    [label]="vm.isSidebarReduced?undefined: (pageLayoutDictionary.feedbackButton | translate)"
                    iconName="notification.news" (onClick)="sentryFeedback()"></albi-button></albi-vertical-tabs>
        </nav>
        <div class="page-wrapper">
            <app-top-bar *ngIf="showTopbar">
                <albi-button *ngIf="vm.isMobileView" iconName="menu.hamburger" size="small" class="align-self-center"
                    (onClick)="changeIsSidebarReduced(!vm.isSidebarReduced)"></albi-button>
            </app-top-bar>
            <div #pageNotification class="main-page-notification">
                <span class="delete"
                    *ngIf="vm.user?.deleteRequest">{{pageLayoutDictionary.staticMessage.deleteRequestMessage |
                    translate}}</span>
                <!-- Message to tell user limited mode-->
                <ng-container *ngIf="vm.selectedUserAccess?.role?.name==='AdminUnverified';else normalRoleMessages">
                    <span class="warning">{{pageLayoutDictionary.staticMessage.pendingDocumentsMessage |
                        translate}}</span>
                </ng-container>
                <!-- Various messages if company has been approved with documents-->
                <ng-template #normalRoleMessages>
                    <span class="success" *ngIf="vm.companyHasPendingUsers">
                        <a href="dashboard/company/users">{{pageLayoutDictionary.staticMessage.handleNewConnection |
                            translate}} </a>
                    </span>
                </ng-template>
                <span *ngFor="let message of pageHeaderMessages" [class]="message.alert"
                    class="d-flex j-center align-center gap-12">
                    <span>
                        {{message.messageText}}
                    </span>
                    <a [href]="message.linkUrl" target="_blank" *ngIf="message.linkText && message.linkUrl">
                        {{message.linkText}}</a>
                </span>
            </div>
            <select-enterpriseAccess *ngIf="vm.showEnterpriseCompanyAccessPanel"></select-enterpriseAccess>
            <div class="dashboard-message-panel" id="dashboard-message-panel">
            </div>
            <div class="page-body" id="page-body">
                <div class="body-wrapper">
                    <div class="content-wrapper">
                        <ng-content></ng-content>
                        <!--<router-outlet></router-outlet>-->
                    </div>
                </div>
            </div>
            <div class="page-footer" id="page-footer">
            </div>
        </div>
    </div>
</ng-container>