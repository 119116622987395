const DASHBOARD_SHARED = {
    pendingDocumentCard: {
        title: `Verify documents!`,
        description: `To ensure the security and reliability of companies within the dashboard, 
                        you need to complete the document verification. Once verified,
                        you will have full access as admin of the selected company and 
                        all your creations will be active.`,
        button: `Verify documents and become admin`,
    },
    goToShop: `Go to the shop`,
    goToHandleChassis: `Go to chassis management`,
    maxLenght30: `Maximum length is 30 characters`,
    slotNumber: `Slot {{slotNumber}}`,
    slotCounter: `Slot {{slotCounter}}/{{totalSlots}}`
}

export const DASHBOARD_TRANSLATIONS_EN = {
    dashboard: {
        homePage: {
            helloText: `Hello {{name}}`,
            pendingDocumentCard: {
                title: DASHBOARD_SHARED.pendingDocumentCard.title,
                description: DASHBOARD_SHARED.pendingDocumentCard.description,
                button: DASHBOARD_SHARED.pendingDocumentCard.button,
            },
            serviceText: `Albicchiere Services`,
            elabel: {
                title: `ELabel`,
                description: `Create personalized digital labels easily and quickly,
                 thanks to our user-friendly interface. Comply with regulations and offer your
                  loyal consumers a unique wine experience.`,
            },
            wine: {
                title: `Wine`,
                description: `Create and manage your wines! Enrich the wine sheets and improve
                 the experience of your consumers, for unparalleled wine management. Start now to
                  explore and manage the wines you produce with Albicchiere!`,
            },
            serviceButton: `Go`,
        },
        elabel: {
            homePage: {
                title: `ELabel`,
                counter: {
                    scans: `Scans`,
                    elabelNumber: `Digital labels`,
                    vintages: `Vintages`,
                },
                statistics: {
                    comingSoon: `Coming soon!`,
                    title: `eLabel Statistics`,
                    description: `Statistics for your eLabels are coming soon. Find out which of your wines are the most scanned,
                        in which region of the world and at what times.`,
                },
                table: {
                    title: `Your digital labels`,
                    column: {
                        wineName: `Wine name`,
                        updatedAt: `Last update`,
                        model: `Format`,
                        lotCounter: `Number of batches`,
                        scans: `Scans`,
                    }
                }
            },
            creation: {
                title: {
                    newElabel: `Create eLabel`,
                    newLot: `Add new batch`,
                    editElabel: `Edit eLabel`,
                    editLot: `Edit batch`,
                },
                button: {
                    prev: `Back`,
                    next: `Continue`,
                    save: `Save`,
                },
                stepName: {
                    wine: `Wine`,
                    nutrition: `Nutrition`,
                    package: `Package`,
                    layout: `Layout`,
                },

                stepComponents: {
                    stepOne: {
                        languageBox: {
                            title: `Language`,
                            description: `Select the language for creating your eLabel.
                    This will be used as the reference language to generate automatic translations.`,
                            inputTitle: `Creation language`,
                            inputPlaceholder: `Select language`,
                        },
                        wineBox: {
                            title: `Wine`,
                            description: `Select the wine and vintage for which you want to create your eLabel.`,
                            wineInput: `1.Wine`,
                            createWineButton: `Create new wine`,
                            vintageInput: `2.Vintage`,
                            createVintageButton: `Create new vintage`,
                            winePlaceholder: `Select a wine`,
                            vintagePlaceholder: `Select a vintage`,
                        }
                    },
                    stepTwo: {
                        nutritionBox: {
                            title: `Nutrition`,
                            description: `   Enter residual sugar and tartaric acid to automatically calculate the energy values of your
                            wine.`,
                            alcoholInput: `Alcohol content (i)`,
                            sugarInput: `Residual sugar`,
                            tartaricInput: `Tartaric acid`,
                            caloriesText: `Energy value per 100ml:`,
                            caloriesValue: `{{calories}} kcal`,
                            calcSectionTitle: `Calculated values`,
                            calcSectionDescr: `Values are automatically calculated from alcohol content, residual sugar and tartaric
                            acid.`,
                            eneryInput: `Energy`,
                            carbsInput: `Carbohydrates`,
                            sugarCarbsInput: `of which sugars`,
                            residualValueSectionTitle: `Residual values`,
                            residualValueSectionDescr: `Values are typically equal to 0 and can be displayed as small quantities.`,
                            fatInput: `Fat`,
                            saturatedFatInput: `Saturated fat`,
                            proteinInput: `Protein`,
                            saltInput: `Salt`,
                        },
                        ingredientsBox: {
                            title: `Ingredients`,
                            description: `Select the ingredients that must be declared on your elabel. Please note that the
                    selection of ingredients must comply with the relevant national regulations. Below
                    are listed only the ingredients allowed by the EU for the specific production of wine.`,
                            smallQuantities: `small quantities`,
                            allergen: `allergen`,
                            addButton: `Add`,

                        },
                        ingredientsPanel: {
                            title: `Ingredients`,
                            description: `
                            Select the ingredients that must be declared on your elabel. Please note that
                            the
                            selection of ingredients must comply with the relevant national regulations. The
                            following
                            are listed only the ingredients allowed by the EU for the specific production of wine.`,
                            infoText: `For more information`,
                            button: `Continue`,
                        }
                    },
                    stepThree: {
                        packagingBox: {
                            title: `Packaging`,
                            description: `Select the format of your packaging and enter the materials it is
                made of if you want to advise consumers on recycling methods (this section is mandatory for products sold in the
                Italian market).`,
                            modelInput: `Select the format and size of your packaging *`,
                            modelPlaceholder: `Select a format`,
                            enviroment: {
                                title: `Environmental label`,
                                description: `Activate this section to show recycling information. Remember that for products sold in
                    Italy it is mandatory to show packaging materials and other details.`,
                            },
                            components: {
                                title: `Packaging components and materials`,
                                description: `Add the components that make up your packaging or import them through a saved template
                    previously.`,
                                importButton: `Import from template`,
                            },
                            saveTemplateText: `Press “Save as template” to create a new template and reuse it in the future.`,
                            saveTemplateButton: `Save as template`,
                        },
                        certificateBox: {
                            title: `Certificates`,
                            description: `Select the sustainability certificates of your wine.`,
                        },
                        warningBox: {
                            title: `Warnings`,
                            description: `Select the messages for responsible consumption that you intend to show in the eLabel`,
                        },
                        productionInfoBox: {
                            title: `Production Information`,
                            description: `Select the data relating to the producer, bottler and importer for each
                country in which your product is
                marketed.`,
                        },
                        legalInfoBox: {
                            title: `Legal Notices`,
                            description: `Enter the imprint to comply with local regulations. Please note that there is no
                automatic translation for this module, so you will need to enter the text directly in the language of the
                market.`,
                        },
                        productionOperators: {
                            bottler: `Bottler`,
                            importer: `Importer`,
                            producer: `Producer`,
                        },
                        //dialog panels
                        modelImportPanel: {
                            title: `Select Model`,
                            subtitle: `Model name`,
                            description: `Enter the name that will be imported for the model`,
                        },
                        componentsAddPanel: {
                            title: `Component and material`,
                        },
                        certificatePanel: {
                            title: `Certificates`,
                            description: `Select the sustainability certificates of your wine. You can add the code and date of delivery for
            each certificate.`,
                            codePlaceholder: `Code`,
                            dataPlaceholder: `Date`,
                        },
                        warningPanel: {
                            title: `Warnings`,
                            description: `Select the messages for responsible consumption that you intend to show in the eLabel.`,
                        },
                        productionInfoPanel: {
                            title: `Production Information`,
                            description: `Select the type of operator, entering the company name and address. Indicate for which countries the operator
            must be displayed.`,
                        },
                        legalInfoPanel: {
                            title: `Legal Notices`,
                            description: `Enter the imprint and select the countries for which you want to show this message.`,
                        },
                        newModelPanel: {
                            title: `Save template`,
                            subtitle: `Model name`,
                            description: `Enter the name you want to associate with your template to save it`,
                            namePlaceholder: `Name`,
                        },
                    },
                    stepFour: {
                        templateStyleBox: {
                            title: `Template style`,
                            description: `Make your digital label unique and recognizable. Customize the style of your eLabel page
                                          by matching it to the colors of your brand or the packaging of your wine. Each batch contained in this eLabel
                                          will inherit the same settings.`,
                            backgroundInput: `Background color`,
                            sheetInput: `Card color`,
                            colorInfoText: `*We recommend choosing light and muted colors to allow the reading of black text.
            `,
                        },
                        qrStyleBox: {
                            title: `QR code style`,
                            description: `Choose the configuration, color and transparency of your QR code. Customize it to suit your
                needs.`,
                            qrColor: {
                                title: `QR color`,
                                description: `Change the colour of the lines in your QR code`,
                                transparencyTitle: `Apply transparency`,
                                transparencyDescription: `By applying transparency, you can save the file without a white background.`,

                            },
                            patternTitle: `Pattern`,
                            previewTitle: `Preview`,
                        },
                    },
                    createVintage: {
                        title: `eLabel - Create Vintage`,
                    }
                }
            },
            detail: {
                title: `Overview`,
                qrBox: {
                    title: `eLabel QR`,
                    description: `Download the QR Code of the created label, which will be available only after publication and
                                    will remain accessible for multiple batches. For a specific batch, use the dedicated QR Codes.`,
                },
                table: {
                    title: `Batches of your eLabel`,
                    editElabelButton: `Edit eLabel`,
                    addLotButton: `Add batch`,
                    depublishButton: `Hide`,
                    publishButton: `Publish`,
                    publicLabel: `Published`,
                    draftLabel: `Draft`,
                    columns: {
                        state: `State`,
                        lotName: `Batch name`,
                        lastUpdate: `Last update`,
                        scans: `Scans`,
                    },
                    deleteLotPanel: {
                        title: `Delete batch`,
                        subtitle: `Attention! You are about to delete the eLabel for this batch`,
                        description: `Are you sure you want to delete the elabel for this batch? If you proceed, all data will be deleted and you will not
            be able to recover it.`,
                        deleteButton: `Delete`,
                    },
                    depublishPanel: {
                        title: `Depublish batch`,
                        subtitle: `This batch will no longer be visible online!`,
                        //desciption use white-space:pre-line -> to go to new line after first phrase
                        description: `The status of this batch will change from “active” to “draft”. 
            This batch will no longer be accessible to your consumers, are you sure you want to proceed? Press Hide to not show this batch. Press X to go back.`,
                        depublishButton: `Hide`,
                    },
                    publishPanel: {
                        title: `Publish batch`,
                        description: `Press "Publish" to publish the batch, press X to go back. Once published, your
            page will be accessible via QR code or link.`,
                        lotNameTitle: `Batch name`,
                        lotNameDescription: `Give your batch a name if you plan to produce more batches for the same vintage. This name allows your
            consumer to view the eLabel of the correct batch. We recommend using the same name that
            you will print on your physical label.`,
                        publishButton: `Publish`,
                    }
                }
            }
        },
        wine: {
            homePage: {
                title: `Wines`,
                counter: {
                    wines: `Wines`,
                    vintages: `Vintages`,
                    elabels: `ELabels`,
                },
                table: {
                    title: `The wines you produce`,
                    searchPlaceholder: `Search`,
                    column: {
                        wineName: `Wine name`,
                        lastUpdate: `Last update`,
                        averageRating: `Average rating`,
                    }
                }
            },
            create: {
                title: `Create Wine`,
            },
            detail: {
                title: `Wine detail`,
                addVintageButton: `Add vintage`,
                infoText: `Remember to complete the wine sheet and check the accuracy of the information.`,
                wineDataBox: {
                    color: `Color`,
                    region: `Region`,
                    grapes: `Grapes`,
                },
                vintageDetailBox: {
                    title: `Vintage data`,
                    temperature: `Serving temperature`,
                    alcohol: `Alcohol content`,
                    allergens: `Allergens`,
                    philosophy: `Philosophy`,
                    descriptionField: `Description`,
                    pairings: `Pairings`,
                    moods: `Mood`,
                    awards: `Awards`,
                }
            },
            editWine: {
                title: `Edit vintage`,
                wineCreationDescription: `The wine data is common to all vintages. By modifying them in a vintage sheet, 
                the changes will be extended to all other vintages of the same connected wine.
                 If you still want to modify the wine fields, unlock the selector on the right.`,
            },
            addVintage: {
                title: `Add a new vintage`
            }
        },
        company: {
            homePage: {
                title: `Company`,
                seeDetailButton: `See detail`,
                usersTitle: `Connected users`,
                pendingDocumentCard: {
                    title: DASHBOARD_SHARED.pendingDocumentCard.title,
                    description: DASHBOARD_SHARED.pendingDocumentCard.description,
                    button: DASHBOARD_SHARED.pendingDocumentCard.button,
                },
                partnerBox: {
                    title: `Partners`,
                    description: `Coming soon! The new management service through partner companies to simplify your work.
                    Finally, you will be able to delegate the management of your workspace to trusted professionals.`,
                },
                serviceBox: {
                    title: `Service area`,
                    description: `Coming soon! The service area is coming, where you can manage your active services and subscribe to new ones, designed for wineries and wine professionals.`,
                },
                table: {
                    title: `Company locations`,
                    columns: {
                        name: `Name`,
                        address: `Address`,
                        users: `Users`,
                    }
                },
                addressPanel: {
                    title: `Add Address`,
                }
            },
            documentVerification: {
                title: `Document verification`,
                description: `The security of companies is important to us.
                             To prevent unauthorized individuals from accessing your company's profile,
                             we need some verification documents. Find out which documents you can
                             upload to become the company admin and make all creations effective.`,
                noDocumentsLoaded: `No documents uploaded`,
                personalDocument: `Personal documents`,
                companyDocuments: `Company documents`,
                loadDocumentsButton: `Upload Documents`,
                findOutButton: `Find out which`,
                findOutPanel: {
                    title: `Identification documents`,
                    description: `To prove that you are the owner of the company you are 
                                  claiming, you can upload some of the following documents.`,
                    personalTitle: `Personal documents: `,
                    companyTitle: `Company documents: `,

                    bankStatement: `Bank statement`,
                    registrationCertificate: `Certificate of incorporation`,
                    companyBilling: `Utility bill`,

                    identityCard: `Identity card`,
                    drivingLicence: `Driver's license`,
                    passport: `Passport`,
                }
            },
            detail: {
                title: `Company detail`,
                formTitle: `Company data`,
                formDescription: `Check and, if necessary, modify the data of your company.`,
            },
            user: {
                title: `Connected users`,
                companyAdmin: `Company admin`,
                connectedUser: `Connected users`,
                locationSelectPlaceholder: `Select a location`,
                approveButton: `Approve`,
                declineButton: `Decline`,
                pendingText: `Wants to connect to your company`,
                roleEditPanelTitle: `Role Edit`,
                userAcceptPanelTitle: `Role Assignation`,
                inviteUserPanel: {
                    title: `Invite to your Workspace`,
                    description: `You are inviting a new user to your company. Enter 
                                    his email and select a role, then press send invitation.`,
                    emailPlaceholder: `Email`,
                    accessTitle: `Access`,
                    accessDescription: `Select company if you want to assign a company-wide role to your user.
                                         Instead, select location if you want to assign the user a different role
                                          for each location of your company.`,
                    roleTitle: `Role`,
                    locationRoleDescription: `Select the location and the related role you intend to assign to the user. Save to continue.`,
                    companyRoleDescription: `Select role and save to continue.`,
                    addLocation: `Add location`,
                },
                invitedUserText: `Invited user`,
                activeInviteTagLabel: `Invite active`,
                expiredInviteTagLabel: `Invite expired`,
                resendInviteButton: `Resend invite`,
                inviteExpirationText: `Expiration: {{expiryAt}}`,
            },
            locationDetail: {
                title: `Location detail`,
                isBilling: `Billing`,
                isShipping: `Shipping`,
                locationAdminText: `{{name}} is the admin of the Location`,
                userNumberText: `{{usersLength}} Users`,

                companyAdminTitle: `Company Admin`,
                locationUserTitle: `Location Users`,
                locationEditPanelTitle: `Location Data`,
                safeDeletePanel: {
                    title: `Delete Location`,
                    subtitle: `You are deleting the location {{locationName}}`,
                    description: `If you proceed, the user will no longer be able to work for the company. Press remove to disconnect the
                user, instead
                press x to go back.`,
                }
            }
        },
        dispenser: {
            homePage: {
                topBar: {
                    title: `Dispenser`,
                    companyText: `Go to the <a href="dashboard/company">Company</a> section to
                        create
                        a new location.`,
                },
                errorCard: {
                    title: `Dispenser Alerts`,
                    switchText: `Show all`,
                    errorText: `Detected "{{error}}" on {{dispenserName}}, at the location {{locationName}}`,
                },
                dispenserSectionTitle: `Your Albi Home`,
                chassisSectionTitle: `Your Albi Pro`,
                chassisButton: DASHBOARD_SHARED.goToHandleChassis,
                slotCounter: DASHBOARD_SHARED.slotCounter,
                slotNumber: DASHBOARD_SHARED.slotNumber,
                shopCard: {
                    text: `Add Smart Wine Bags and Smart Dispensers directly from your dashboard.`,
                    button: DASHBOARD_SHARED.goToShop,
                },
                spaceHandleTitle: `Maintain control of every space`,
                handleButton: `Manage`,
                chassisCard: {
                    title: `Chassis`,
                    text: `Order Pro dispensers`,
                },
                roomCard: {
                    title: `Rooms`,
                    text: `Place your dispensers`,
                },
                emptyLocationText: `Add a dispenser to this location to 
                maximize your profits`,
                emptyLocationButton: DASHBOARD_SHARED.goToShop,
            },
            dispenserDetail: {
                title: `Dispenser Detail`,
                alertTitle: `Dispenser Alert`,
                bagId: `Bag Id {{bagSerial}}`,
                chassisData: {
                    slotNumber: DASHBOARD_SHARED.slotNumber,
                    roomLocation: `in {{locationName}}, {{roomName}}`,
                },
                location: `Location`,
                room: `Room`,
                coolingMode: `Cooling Mode`,
                wifi: `Wi-Fi`,
                batteryLevel: {
                    label: `Battery Level`,
                    none: `None`,
                    empty: `Empty`,
                    low: `Low`,
                    half: `Half`,
                    full: `Full`,
                    emptyCharging: `Empty, charging`,
                    lowCharging: `Low, charging`,
                    halfCharging: `Half, charging`,
                    fullCharging: `Full, charging`,
                    plugged: `Plugged`,
                },
                model: `Model`,
                firmware: `Firmware`,
                macAddress: `MAC Address`,
                productSerial: `Product Serial`,

                editDispenserPanel: {
                    title: `Edit Home Dispenser`,
                    description: `Edit your dispenser's data.`,
                    name: `Dispenser Name`,
                    nameError: DASHBOARD_SHARED.maxLenght30,
                    room: `Room`,
                    coolingMode: `Cooling Mode`,
                },
                parentalControlPanel: {
                    activeTitle: `Parental Control Active`,
                    inactiveTitle: `Parental Control Inactive`,
                    activeDescription: `Parental control on your dispenser is now active. By disabling it, any user can
                            dispense wine from the dispenser without app authorization.`,
                    inactiveDescription: `Parental control on your dispenser is now inactive. By enabling it, the dispenser can
                            dispense only with app authorization.`,
                    activateButton: `Activate`,
                    deactivateButton: `Deactivate`,
                },
                factoryResetPanel: {
                    title: `Factory Data Reset`,
                    description: `Proceeding with the factory data reset will disconnect this dispenser from your account, and you will need
                            to pair it again to use it. Press Factory Data Reset if you want to disconnect the dispenser, or press Cancel to go back.`,
                    button: `Factory Data Reset`,
                }

            },
            roomList: {
                title: `Room List`,
                roomName: `Room Name`,
                dispensers: `Associated Devices`,
                location: `Location`,
                newRoomPanel: {
                    title: `Create New Room`,
                    description: `Enter the name of the room you want to create and select the location where it is located.
                        Press Continue to save or Cancel to go back.`,
                    roomName: `Room Name`,
                    roomNameError: DASHBOARD_SHARED.maxLenght30,
                }
            },
            roomDetail: {
                title: `Room Detail`,
                chassisButton: DASHBOARD_SHARED.goToHandleChassis,
                slotNumber: DASHBOARD_SHARED.slotNumber,
                roomEditPanel: {
                    title: `Edit Room Name`,
                    description: `Enter the new room name.
                        Rooms with the same name cannot exist in the same location.`,
                    name: `Room Name`,
                    nameError: DASHBOARD_SHARED.maxLenght30,
                },
                moveDispenserPanel: {
                    title: `Move Device`,
                    description: `In order to disconnect a Dispenser or Chassis from a room, you must pair it with a new one.
                        If you want to delete the device without pairing it to a new room, go to the details and press delete. You will be able to reconnect it later by performing a new pairing process.
                        Press Continue to save or Cancel to go back.`,
                    name: `Room Name`,
                },
                deleteRoomPanel: {
                    title: `Delete Room`,
                    description: `Are you sure you want to delete the room?
                        Press Continue to proceed and delete the room. If you don't want to proceed, press Cancel to go back.`,
                }
            },
            chassisList: {
                title: `Chassis List`,
                name: `Chassis Name`,
                dispensers: `Pro Dispensers`,
                slot: `Slot`,
                location: `Location`,
                room: `Room`,
                createChassisPanel: {
                    title: `Create New Chassis`,
                    description: `Enter the name of the chassis you want to create and the number of 
            Slots it can manage. Also, select the Location and Room where you want to place it.
            Press Continue to save or Cancel to go back.`,
                    name: `Chassis Name`,
                    nameDescription: `Enter the name of your chassis. Maximum 30 characters.`,
                    nameError: `Required field, maximum length 30 characters`,
                    totalSlots: `Total Slots`,
                    totalSlotsError: `Required field, (minimum 2, maximum 7)`,
                    room: `Room`,
                    roomError: `Required field`,
                }
            },
            chassisDetail: {
                title: `Chassis Detail`,
                slotCounter: DASHBOARD_SHARED.slotCounter,
                slotNumber: DASHBOARD_SHARED.slotNumber,
                emptySlot: `Empty Slot`,
                addDispenserText: `Add a Pro!`,
                editChassisPanel: {
                    title: `Edit Chassis`,
                    description: `Edit the chassis fields.
                        Press Continue to save or Cancel to go back.`,
                    name: `Chassis Name`,
                    nameError: DASHBOARD_SHARED.maxLenght30,
                    totalSlot: `Total Slots`,
                    totalSlotError: `Minimum 2, maximum 7`,
                    room: `Room`,
                },
                changeSlotPanel: {
                    title: `Slot Position`,
                    description: `Modify the position of dispensers within the chassis.`,
                    slot: DASHBOARD_SHARED.slotNumber,
                },
                deleteChassisPanel: {
                    title: `Delete Chassis`,
                    description: `Are you sure you want to delete the chassis?
                        Press Continue to proceed and delete the chassis.
                        If you don't want to proceed, press Cancel to go back.`,
                },
            }


        },
        cantina: {
            homePage: {
                title: `Cellar`,
                tableTitle: `Vintages in the cellar`,
                searchPlaceholder: `Search`,
                columnName: {
                    wineName: `Wine Name`,
                    winery: `Winery`,
                    quantity: `Quantity`,
                    parentalControl: `Parental Control`,
                },
                closedSmartbag: `Smartbag (closed)`,
                closedSmartbagTag: `Sealed Smartbags`,
                neverOpened: `Never opened`,
            }
        },
        wineList: {
            homePage: {
                title: `Wine List`,
                activeListSection: {
                    title: `The Active List`,
                    description: `Use this QR code to display your Wine List.
                     The QR will remain the same even if you change the linked menu.
                     Create a placeholder or add it to your menu for customers to scan,
                     or use it for your online applications.`,
                },
                promoBanner: {
                    title: "AI Sommelier",
                    promoText: "AI Sommelier helps you recommend the perfect wine to each of your customers. Activate it now to make the QR codes public and start using it.",
                    expiredText: "Yours has expired. Reactivate your QR codes now and start using your wine lists and Albi AI Sommelier again without losing your data.",
                    reactivateButton: "Reactivate",
                    freeTrialButton: "Try it for free"
                },
                wineListCard: {
                    title: "Wine list",
                    noListTitle: "No menu connected",
                    disconnectButton: "Disconnect",
                    listDescription: "To hide any connected menu, press the button on the right.",
                    noListDescription: "To create or link a Wine List, go to the table below.",
                    menuTitle: "Menu",
                    menuDishes: "{{dishes}} dishes on the menu",
                    menuDescription: "Upload your full menu to provide an even more personalized experience. Albi AI can retrieve the ingredients of your dishes, but if you want to highlight the special flavors of your cuisine, enter the most important ingredients."
                },
                qrCard: {
                    title: "QR code",
                    sommelierTitle: "Sommelier",
                    sommelierDescription: "Use this QR on your existing or printed wine lists. Your customers can activate Albi AI Sommelier by scanning the QR code with their smartphones.",
                    winelistTitle: "Wine list",
                    winelistDescription: "Use this QR to display your digital Wine Lists. Albi AI Sommelier can be accessed directly within the digital menu."
                },
                wineListSection: {
                    title: `Your Wine Lists`,
                    description: `Create multiple lists for different moments or seasons
                     without deleting the old ones, and activate the one you want to display.`,
                },
                wineListTable: {
                    title: `List`,
                    addWineList: `Add Wine List`,
                    columns: {
                        name: `Wine List Name`,
                        wines: `Number of Wines`,
                        updatedAt: `Last Updated`,
                    },
                }
            },
            detailPage: {
                title: `List Details`,
                infoSection: {
                    title: `Information`,
                    description: `Edit the template you created and customize it to ensure a unique experience.`,
                },
                listName: {
                    title: `List Name *`,
                    description: `Enter the name of the Wine List.`,
                },
                winesSection: {
                    title: `Your Wine List`,
                    description: `Enter the wines you want to offer your customers and specify the sales conditions.`,
                },
                selectedWinesTable: {
                    title: `Published List`,
                    addFormat: `Add format`,
                    columns: {
                        wineName: `Wine Name`,

                        glass: `Glass`,
                        glassCapacity: `Glass {{capacity}} ml`,
                        bottleCapacity: `Bottle {{capacity}} ml`,
                        jugCapacity: `Jug {{capacity}} ml`,
                    }
                },
                configurationSection: {
                    title: `Configuration`,
                    description: `Edit the template you created and customize it to ensure a unique experience.`,
                },
                extraWineInfo: {
                    title: `Additional Information`,
                    description: `Text description`,
                    grapes: `Grape Composition`,
                    philosophy: `Wine Philosophy (Vegan, Organic, Low Alcohol, No Alcohol)`,
                    alcohol: `Alcohol Percentage`,
                    foodPairing: `Food Pairings`,
                },
                listCategories: {
                    title: `Sections`,
                    albicchiere: `Add By the Glass Category`,
                    lowAlcohol: `Add Extra Low Alcohol Category`,
                },
                templateSection: {
                    title: `Choose Your Template`,
                    description: `Edit the template you created and customize it to ensure a unique experience.`,
                },
                bottomBar: {
                    saveView: `Save and View`,
                    saveClose: `Save and Close`,
                },

                addFormatPanel: {
                    title: `Add a Format`,
                    description: `Choose the format and enter the capacity in ml.`,
                    formatTitle: `Format *`,
                    capacityTitle: `Capacity`,
                },

                addWinesPanel: {
                    title: `Add Wines`,
                    subtitle: `Albicchiere Catalog`,
                    all: `All`,
                    selected: `Selected`,

                    tableColumns: {
                        wineName: `Wine Name`,
                        winery: `Winery`,
                        year: `Vintage`,
                        region: `Region`,
                    },

                    notFoundText: `The wine you searched for is not in the Albicchiere catalog. Fill in the fields and press "Add Wine" to insert it into your list.`,
                    wineName: `Wine Name`,
                    wineryName: `Winery Name`,
                    year: `Vintage`,
                    color: `Color`,
                    alcohol: `Alcohol Volume`,
                },
                popupMessages: {
                    danger: {
                        nameAndWineWarning: `Insert name and select at least one wine`,
                        pricesWarning: `Insert at least one valid price for each wine`,
                    }
                }
            },
            menuPage: {
                title: `Menu`,
                subtitle: `Menu of the location`,
                description: `Enter here the dishes you offer to your customers. Remember that accurate descriptions
                of the dishes improve the user experience and allow Albi AI sommelier to create
                much more precise pairings.
                <br />
                <br />
                TIPS: Don't forget to include the ingredients with the most important flavors and aromas regardless of
                their quantity. These are the ones that determine the best pairing.`,
                categories: {
                    appetizers: `Appetizers`,
                    first_course: `First courses`,
                    second_course: `Main courses`,
                    pizza: `Pizza`,
                    sides: `Side dishes`,
                    desserts: `Desserts`,
                },
                categoryText: {
                    appetizers: `Enter the appetizers of your menu here.`,
                    first_course: `Enter the first courses of your menu here.`,
                    second_course: `Enter the main courses of your menu here.`,
                    pizza: `Enter the pizzas of your menu here.`,
                    sides: `Enter the side dishes of your menu here.`,
                    desserts: `Enter the desserts of your menu here.`,
                },
                addDish: `Add dish`,
                addDishPanel: {
                    description: `Enter the name and description of the dish.
                     Remember that a good description includes the main ingredients and the preparation method,
                     ideal for properly informing the customer about the ideal wine. Maximum 280 characters.`,
                    dishName: `Dish name *`,
                    dishDescription: `Dish description`,
                },
                removeDishPanel: {
                    title: `Remove dish`,
                    description: `Remove the dish {{dishName}}?`,
                }
            }

        }
    }
}