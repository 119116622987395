export const ENVIRONMENT_CONFIG = {
    dev: {
        apiUrl: 'https://api-dev.albicchiere.com',
        cognitoUserPool: 'eu-west-1_BnxRNXEeL',
        cognitoClientId: 'pt38t1qd8l7564813bh8pm0v6',
        externalElabelUrl: 'https://elabel-dev.albicchiere.com',

        cognitoDomain: 'https://auth-dev.albicchiere.com',
        redirectUrl: 'https://dashboard-dev.albicchiere.com/callback',

    },
    staging: {
        apiUrl: 'https://api-staging.albicchiere.com',
        cognitoUserPool: 'us-east-1_1zaTQGSFY',
        cognitoClientId: '2etmbftl1nd6d96q7723uc5s1i',
        externalElabelUrl: 'https://elabel-staging.albicchiere.com',

        cognitoDomain: 'https://auth-staging.albicchiere.com',
        redirectUrl: 'https://dashboard-staging.albicchiere.com/callback',
    },
    prod: {
        apiUrl: 'https://api.albicchiere.com',
        cognitoUserPool: 'eu-west-1_Mq2rXIDUU',
        cognitoClientId: '4saefnns4317ni5ivbar7li32',
        externalElabelUrl: 'https://elabel.albicchiere.com',

        cognitoDomain: 'https://auth.albicchiere.com',
        redirectUrl: 'https://dashboard.albicchiere.com/callback',
    }
}