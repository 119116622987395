import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ErrorHandler, NgModule, inject, provideAppInitializer } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import * as Sentry from "@sentry/angular";
import { provideNgxStripe } from 'ngx-stripe';
import { AuthGuard } from "src/authentication/guards/auth.guard";
import { AuthenticationEffects } from "src/authentication/store/authentication.effects";
import { authenticationReducer } from "src/authentication/store/authentication.reducers";
import { CompanyGuard } from "src/dashboards/dashboard/guards/company.guard";
import { HeaderMessageService } from "src/services/header-message.service";
import { MessageService } from "src/services/message.service";
import { UserAccesEffects } from "src/userAccessStore/userAccessStore.effects";
import { userAccessReducer } from "src/userAccessStore/userAccessStore.reducers";
import { HomeComponent } from "../home/home.component";
import { AuthService } from "../services/auth.service";
import { BackendService } from "../services/backend.service";
import { LocalStorageService } from "../services/local-storage.service";
import { AlbiTranslateLoader } from "./albiTranslateLoader";
import { DECLARATIONS, ROUTES } from "./app.routing";
import { SharedModule } from "./shared.module";

@NgModule({
    declarations: [
        ...DECLARATIONS,
    ],
    bootstrap: [HomeComponent],
    imports: [BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: AlbiTranslateLoader
            }
        }),
        FormsModule,
        RouterModule.forRoot(ROUTES),
        StoreModule.forRoot({ authentication: authenticationReducer, userAcces: userAccessReducer /*, onboarding: onboardingReducer */ }),
        EffectsModule.forRoot([AuthenticationEffects, UserAccesEffects /*, OnboardingEffects*/]),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: true, // Restrict extension to log-only mode
        }),
        SharedModule],
    providers: [{
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
            showDialog: false,
        }),
    }, {
        provide: Sentry.TraceService,
        deps: [Router],
    },
    provideAppInitializer(initializeTracing()),
    provideNgxStripe('pk_test_51R3fj4IWxqNDapCBGXhCWGM0047xwnjIx2RG47pqWftkT3lHR6l4ziM5btJ2oBk1CvDif3v5tibvmCVOtYmgKT0m00ZlVgXLM2'),
        AuthService,
        AuthGuard,
        CompanyGuard,
        BackendService,
        LocalStorageService,
        MessageService,
        HeaderMessageService, provideHttpClient(withInterceptorsFromDi()),]
})
export class AppModule { }


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

export function initializeTracing(): () => void {
    return () => {
        const traceService = inject(Sentry.TraceService);
        traceService; // Assicura che il servizio venga inizializzato
    };
}