import IUser from '@albi-types/base/user';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'src/services/auth.service';
import { BackendService } from 'src/services/backend.service';
import { USER_ACCESS_API_ACTIONS, USER_ACCESS_PAGE_ACTIONS } from 'src/userAccessStore/userAccessStore.actions';
import { AUTHENTICATION_API_ACTIONS, AUTHENTICATION_PAGE_ACTIONS } from './authentication.actions';
@Injectable()
export class AuthenticationEffects {
    private readonly _destroy: DestroyRef = inject(DestroyRef);

    constructor(
        private actions$: Actions,
        private _authService: AuthService,
        private _router: Router,
        private _backendService: BackendService,
        private _store: Store,) { }

    loadAuthenticationState = createEffect(() =>
        this.actions$.pipe(
            ofType(AUTHENTICATION_PAGE_ACTIONS.initAuthenticationStateFromStorage),
            withLatestFrom(
                of(window.localStorage.getItem('accessToken')),
                of(window.localStorage.getItem('email')),
                of(window.localStorage.getItem('idToken')),
                of(window.localStorage.getItem('refreshToken')),
                of(window.localStorage.getItem('user')),
            ),
            switchMap(([_, token, email, idToken, refreshToken, user]) =>
                this._authService.checkToken({ accessToken: token, email, idToken, refreshToken }).pipe(
                    map(_ => AUTHENTICATION_API_ACTIONS.authenticateSuccess({
                        accessToken: token,
                        email: email,
                        idToken: idToken,
                        refreshToken: refreshToken,
                        user: user ? (JSON.parse(user) as IUser) : null,
                    })),
                    catchError(_ => of(AUTHENTICATION_API_ACTIONS.authenticateFailure({ error: null/*'no token'*/ })))
                )
            )));

    handleLogin = createEffect(() =>
        this.actions$.pipe(
            ofType(AUTHENTICATION_PAGE_ACTIONS.clickLogin),
            switchMap(({ username, password }) =>
                this._authService.login(username, password).pipe(
                    map(sessionInfo => AUTHENTICATION_API_ACTIONS.authenticateSuccess(sessionInfo)),
                    catchError(error => {
                        return of(AUTHENTICATION_API_ACTIONS.authenticateFailure({ error }))
                    })
                )
            )
        )
    );

    handleRegister = createEffect(() =>
        this.actions$.pipe(
            ofType(AUTHENTICATION_PAGE_ACTIONS.clickRegister),
            switchMap(({ name, lastname, username, password }) =>
                this._authService.register(name, lastname, username, password).pipe(
                    map(sessionInfo => AUTHENTICATION_API_ACTIONS.authenticateSuccess(sessionInfo)),
                    catchError(error => of(AUTHENTICATION_API_ACTIONS.authenticateFailure({ error })))
                )
            )
        )
    );

    updateLoggedUser = createEffect(() =>
        this.actions$.pipe(
            ofType(AUTHENTICATION_PAGE_ACTIONS.updateLoggedUser),
            switchMap(() =>
                this._backendService.get<{ user: IUser }>(`users/me`, { useCompanyHeader: false }).pipe(
                    map(response => AUTHENTICATION_API_ACTIONS.updateLoggedUserSuccess(response)),
                    catchError(error => of(AUTHENTICATION_API_ACTIONS.updateLoggedUserFailure({ error }))),
                )
            )
        )
    );

    updateLoggedUserSuccess = createEffect(() =>
        this.actions$.pipe(
            ofType(AUTHENTICATION_API_ACTIONS.updateLoggedUserSuccess),
            tap((user) => {
                window.localStorage.setItem('user', JSON.stringify(user.user));
            })
        ), {
        dispatch: false
    }
    );



    handleSocialLogin = createEffect(() =>
        this.actions$.pipe(
            ofType(AUTHENTICATION_PAGE_ACTIONS.authenticateSocialLogin),
            switchMap(({ code }) =>
                this._authService.authenticateWithCognito(code).pipe(
                    map(sessionInfo => AUTHENTICATION_API_ACTIONS.authenticateSuccess(sessionInfo)),
                    catchError(error => {
                        return of(AUTHENTICATION_API_ACTIONS.authenticateFailure({ error }))
                    })
                )
            )
        )
    );

    handleLoginSuccess = createEffect(() =>
        this.actions$.pipe(
            ofType(AUTHENTICATION_API_ACTIONS.authenticateSuccess),
            tap(({ accessToken, email, idToken, refreshToken, user }) => {
                this._authService.setSessionInfo({ accessToken, email, idToken, refreshToken, user });
                this._authService.startRefreshTokenTimer();
            }),
            tap(_ => {
                this._store.dispatch(USER_ACCESS_PAGE_ACTIONS.initUserAcces());
                // TODO: improve code to remove setTimeout
                //wait 800ms to let init user acces action to be done so the company guard know if to redirenct on onboarding or let the user go to dashboard
                this.actions$.pipe(
                    ofType(USER_ACCESS_API_ACTIONS.initUserAccesSuccess),
                    tap(_ => {
                        const requestedUrl = this._authService.getRequestedUrl();
                        //if request url is login redirect to dashboard after authentication
                        if (requestedUrl.url.includes('login') || requestedUrl.url.includes('registration')) {
                            this._router.navigate(['dashboard']);
                        } else {
                            //if the request url has query parameters
                            if (requestedUrl.params?.length > 0) {
                                //reduce the query parameters to a map object
                                const params = requestedUrl.params.reduce((acc: { [key: string]: string }, elm) => {
                                    acc[elm.key] = elm.value;
                                    return acc;
                                }, {});
                                //on the navigation pass the url and the map object as query parameters
                                this._router.navigate([requestedUrl.url], {
                                    queryParams: params
                                });
                            } else {
                                //simple request url withouth query parameters
                                this._router.navigate([requestedUrl.url]);
                            }
                        }
                    }
                    ),
                    takeUntilDestroyed(this._destroy)
                ).subscribe()
                /* setTimeout(() => {
                     this._router.navigate([this._authService.getRequestedUrl().includes('login') ? 'dashboard' : this._authService.getRequestedUrl()])
                 }, 800)*/
            })
        ),
        {
            dispatch: false
        }
    );

    handleLoginError = createEffect(() =>
        this.actions$.pipe(
            ofType(AUTHENTICATION_API_ACTIONS.authenticateFailure),
            tap(_ => {
                //Deleted to enable routing on other pages when not logged in
                //issue founbd on social login -> login redirects to callback page
                //was unservable with redirect to login active on this functio

                // this._router.navigate(['authentication/login'])
            })
        ),
        {
            dispatch: false
        }
    );

}