export const AUTHENTICATION_TRANSLATIONS_EN = {
    authentication: {
        login: {
            description: `Login to our wine platform.`,
            emailInput: `Email`,
            googleLogin: `Login with Google`,
            appleLogin: `Login with Apple`,
            passwordInput: `Password`,
            forgotPasswordText: `Forgot password?`,
            loginButton: `Login`,
            register: `If you don't have an account <a class="text-underline" href="authentication/registration">Register</a>`,
            otherwise: `Otherwise`,
        },
        register: {
            description: `Don't use a generic email because with these credentials you can enter the entire Albicchiere ecosystem,
            both at home and at work`,
            nameInput: `Name`,
            lastnameInput: `Last Name`,
            emailInput: `Email`,
            passwordInput: `Password`,
            passwordMessage: `This field is required\n 
            Password must have:\n 
             - at least 1 lower case letter\n 
             - at least 1 upper case letter\n 
             - at least 1 number\n 
             - at least 1 special character (^ $ * . [ ] { } ( ) ? ! @ # % & / \ , > \< ’ : ; | _ ~ \` + - ) `,
            confirmPassword: `Confirm Password`,
            termsAndContitionText: `By continuing, you accept the <a href="https://albicchiere.com/terms-of-service" target="_blank">terms and
                conditions</a> of albicchiere and confirm that you have read the <a
                href="https://albicchiere.com/privacy-policy" target="_blank">privacy policy</a>.`,
            loginButton: `Login`,
            registerButton: `Register`,
        },
        passwordReset: {
            emailStep: {
                description: `Enter your email address to recover your credentials.`,
                email: `Email`,
                sendEmailButton: `Send Email`,
                registerButton: `Register`,
            },
            resetStep: {
                description: `Enter your email address to recover your credentials.`,
                otpInput: `OTP Code`,
                newPassword: `New password`,
                confirmPassword: `Confirm new password`,
                passwordText: `The password must be at least 8 characters long, and must also contain at least one number and
                    a special character`,
                savePasswordButton: `Save new password`,
            },
        },
    }
}