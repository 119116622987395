export const COMPONENTS_TRANSLATIONS_IT = {
    components: {
        addressFormComponent: {
            nameInput: {
                title: 'Nome della location *',
                description: "Inserisci un nome per identificare l'indirizzo.",
            },
            addressCheckbox: {
                shippingText: 'Salva come indirizzo di spedizione',
                billingText: 'Salva come indirizzo di fatturazione',
            },
            addressInput: {
                title: 'Indirizzo *',
                description: "Inserisci l'indirizzo della tua azienda",
                placeholder: 'Via e numero civico',
            },
            cityInput: {
                placeholder: 'Comune',
            },
            stateInput: {
                placeholder: 'Provincia',
            },
            countryInput: {
                placeholder: 'Paese',
            },
            postalCodeInput: {
                placeholder: 'Codice postale',
            },
        },
        appTopbar: {
            searchbarPlaceholder: `Cerca`,
            changeCompany: {
                title: `Cambia Azienda`,
                description: `Seleziona l’azienda per la quale desideri lavorare. In caso di accesso a
                            singole location, seleziona quella in cui vuoi operare.`,
                button: `Collegati a una nuova company`,
            },
            unchangeableCompany: {
                title: `Cambia Azienda`,
                description: `Non puoi cambiare Accesso durante un flusso di creazione,modifica o rimozione.
                        Esci dal flusso o torna alla home per cambiare Accesso`,
            }
        },
        appPageLayout: {
            staticMessage: {
                handleNewConnection: ` Clicca qui per gestire le nuove richieste di collegamento alla tua azienda`,
                deleteRequestMessage: `Hai richiesto di essere cancellato`,
                pendingDocumentsMessage: `Sei in modalità limitata, verifica i documenti per iniziare a operare come Admin della azienda!`,
            },
            feedbackButton: `Invia un feedback`,
            sentryFeedback: {
                nameLabel: `Nome`,
                namePlaceholder: `Il tuo nome`,
                emailLabel: `Email`,
                emailPlaceholder: `your.email@example.com`,
                messageLabel: `Descrizione`,
                messagePlaceholder: `Qual'è l'errore che hai riscontrato? cosa ti aspettavi succedesse?`,
                isRequiredLabel: `(campo obbligatorio)`,
                triggerLabel: "Invia feedback",
                formTitle: "Lascia un feedback",
                submitButtonLabel: "Invia feedback",
                cancelButtonLabel: "Annulla",
                addScreenshotButtonLabel: "Aggiungi uno Screenshot",
                removeScreenshotButtonLabel: "Rimuovi Screenshot",
            },
        },
        companyCreationForm: {
            typeInput: {
                title: 'Tipologia di azienda',
                description: `Seleziona la tipologia della tua azienda. Seleziona “cantina” solo se
                        sei produttore diretto di
                        vino. Reseller, importatori, distributori o utilizzatori dei servizi Albicchiere devono
                        selezionare l’opzione “professional”.`,
                wineryBox: {
                    title: 'Winery',
                    description: 'Seleziona Winery se la tua azienda è una cantina produttrice.',
                },
                profesionalBox: {
                    title: 'Professional',
                    description: 'Seleziona Professional se la tua azienda eroga servizi.',
                }
            },
            imageInput: {
                title: `Logo dell'azienda`,
                description: `Carica il logo del tuo brand.`,
            },
            nameInput: {
                companyTitle: 'Nome azienda *',
                wineryTitle: 'Nome cantina *',
                description: `Questo è il nome con cui la tua cantina è conosciuta nel mondo e
                        comparirà nelle tue
                        etichette`,
            },
            emailInput: {
                title: `Email *`,
                description: `Inserisci l'indirizzo email aziendale di riferimento`,
            },
            fullNameInput: {
                title: `Ragione sociale *`,
                description: `Inserisci la ragione sociale della tua azienda. Questo nome verrà
                        utilizzato come produttore.`,
            },
            vatNumberInput: {
                title: `Partita iva *`,
                description: `Inserisci la partita iva della tua azienda, completa di codice paese
                        (per es. IT00000000000)`,
            },
            websiteInput: {
                title: `Sito web`,
                description: `Inserisci l’URL del tuo sito web`,
            },
            phoneInput: {
                title: `Telefono *`,
                description: `Inserisci il numero di telefono della tua azienda.`,
            },
        },
        impressumBox: {
            description: `Descrizione`,
            nation: `Nazione`,
            inputPlaceholder: `Nome operatore, indirizzo`,
        },
        wineCreationForm: {
            title: `Dati del vino`,
            description: `Inserisci i dati del vino che vuoi creare. 
            Arricchisci la scheda del tuo vino, dai ai tuoi utenti più informazioni possibili 
            per rendere unica e immersiva l’esperienza di ogni tuo vino.`,
            nameInput: {
                title: `Nome *`,
                description: `Inserisci il nome del tuo vino, escludendo l’annata.`,
            },
            colorInput: {
                title: `Colore *`,
                description: `Inserisci il colore del tuo vino.`,
            },
            styleInput: {
                title: `Stile`,
                description: `Inserisci il tipo di vino. Se è un vino fermo, tralascia questo campo.`,
            },
            regionInput: {
                title: `Provenienza *`,
                description: `Seleziona la regione vinicola di provenienza del tuo vino. Se non la trovi, mandaci un feedback
                        per
                        aggiungerla.`,
            },
            grapeInput: {
                title: `Uve`,
                description: `Seleziona tutti gli uvaggi che compongono il tuo vino. Ti suggeriamo di ordinarli per maggiore
                        quantità.`,
            },
            selectedWineView: {
                title: `Dati del vino`,
                name: `Nome`,
                color: `Colore`,
                region: `Provenienza`,
                grapes: `Uve`,
            }
        },
        vintageCreationForm: {
            title: `Dati dell'annata`,
            description: `Inserisci ora le caratteristiche specifiche della annata`,
            year: {
                title: `Annata *`,
                description: `Inserisci l’annata del vino o seleziona il bottone NV per vini senza annata.
                            Il campo “Edizione speciale” è obbligatorio per tutti i vini NV e per i vini in cui hai già
                            un’annata
                            preesistente. Inserisci un testo per differenziarlo e renderlo ricercabile dai consumatori
                            (es:
                            edizione speciale, xmas box, ecc).`,
                vintageOption: {
                    title: `Vintage`,
                    description: `Per vini con annata`,
                    vintagePlaceholder: `Seleziona l'annata`,
                },
                nvOption: {
                    title: `N.V.`,
                    description: `Per vini senza annata`,
                },
                yearDetailPlaceholder: `Edizione speciale`,
            },
            images: {
                title: `Immagini`,
                description: `Carica l’immagine della etichetta e della bottiglia del tuo vino. Segui i formati o il rapporto
                        indicato. Le immagini caricate saranno visualizzate dai consumatori quindi ti suggeriamo di
                        seguire le indicazioni sotto.`,
                labelImage: {
                    title: `Etichetta`,
                    description: `La dimensione deve essere 480px x 640px`,
                },
                bottleImage: {
                    title: `Bottiglia`,
                    description: `L'immagine deve avere un altezza di 960px`,
                },
            },
            descriptionInput: {
                title: `Descrizione`,
                description: `Inserisci la descrizione del tuo vino per dare maggiori informazioni in merito ai processi
                            di
                            produzione, aromi e storia del tuo prodotto. Seleziona in alto la lingua per la quale vuoi
                            realizzare la tua descrizione.`,
                langPlaceholder: `Lingua`,
            },
            alcohol: {
                title: `Volume alcolico *`,
                description: `Inserisci il corretto volume alcolico per automatizzare il calcolo
                        dei valori energetici.`,
            },
            temperature: {
                title: `Temperatura di servizio *`,
                description: `Indica la temperatura alla quale dovrebbe essere servito il tuo vino. Garantisci
                        un’esperienza di degustazione perfetta ai tuoi consumatori.`,
            },
            primaryPairings: {
                title: `Abbinamenti perfetti`,
                description: `Inserisci fino a 3 food, che ritieni perfetti per accompagnare il tuo vino. Compariranno tra i
                        suggeriti.`,
            },
            secondaryPairings: {
                title: `Abbinamenti ottimi`,
                description: `Inserisci fino a 6 food che si abbinano bene al tuo vino ma non compariranno tra i suggeriti.`,
            },
            mood: {
                title: `Mood`,
                description: `Seleziona gli stati d’animo ideali per degustare il tuo vino.`,
            },
            philosophy: {
                title: `Filosofia`,
                description: `Indica con quale filosofia produttiva è stato realizzato la tua annata.`,
            },
            allergen: {
                title: `Allergeni`,
                description: `Seleziona gli allergeni che fanno parte dell’annata`,
            },
            awards: {
                title: `Riconoscimenti`,
                description: `Inserisci i riconoscimenti ricevuti da questa annata e premi invio per salvare il testo digitato.`,
            },

            cropLabelPanel: {
                title: `Errore dimensioni immagine etichetta`,
                description: `L'immagine caricata non rispetta le dimensioni richieste. È necessario ritagliarla prima di procedere.`,
            },
            cropBottlePanel: {
                title: `Errore dimensioni immagine bottiglia`,
                description: `L'immagine caricata non rispetta le dimensioni richieste. È necessario ritagliarla prima di procedere.`,
            }
        },
        userRoleForm: {
            access: {
                title: `Accesso`,
                description: `Seleziona company se vuoi assegnare un suolo ruolo per tutta la company al tuo
            utente. Invece, seleziona
            location se vuoi assegnare un al to utente un ruolo diverso per ogni location della tua company.`,
                inviteUserDescription: `Stai invitando un utente alla tua azienda. 
            Inserisci la sua email, seleziona un ruolo e premi invia invito.`,
                locationOption: `Location`,
                companyOption: `Company`,
            },
            role: {
                title: `Ruolo`,
                locationRoleDescription: `Seleziona la location e il relativo ruolo che intendi assegnare all’utente. Fai salva per continuare.`,
                companyRoleDescription: `Seleziona il ruolo e fai salva per continuare.`,
                addLocationButton: `Aggiungi Location`,
            }
        }
    },
}