export const SHARED_DICTIONARY = {
    button: {
        add: `shared.button.add`,
        next: `shared.button.next`,
        prev: `shared.button.prev`,
        save: `shared.button.save`,
        apply: `shared.button.apply`,
        change: `shared.button.change`,
        cancel: `shared.button.cancel`,
        continue: `shared.button.continue`,
        download: `shared.button.download`,
        edit: `shared.button.edit`,
        go: `shared.button.go`,
        delete: `shared.button.delete`,
        send: `shared.button.send`,
        activate: `shared.button.activate`,
        deactivate: `shared.button.deactivate`,
    },
    messages: {
        noItemFound: `shared.messages.noItemFound`,
    },
    inputErrors: {
        valueGreaterThenZero: `shared.inputErrors.valueGreaterThenZero`,
    },
    wine: {
        color: {
            red: `shared.wine.color.red`,
            white: `shared.wine.color.white`,
            rose: `shared.wine.color.rose`,
            redSparkling: `shared.wine.color.redSparkling`,
            whiteSparkling: `shared.wine.color.whiteSparkling`,
            roseSparkling: `shared.wine.color.roseSparkling`,
        },
        types: {
            sparkling: `shared.wine.types.sparkling`,
            fortified: `shared.wine.types.fortified`,
            sweet: `shared.wine.types.sweet`,
        }
    },
    company: {
        type: {
            winery: `shared.company.type.winery`,
            professional: `shared.company.type.professional`,
        }
    },
    user: {
        gender: {
            male: `shared.user.gender.male`,
            female: `shared.user.gender.female`,
            other: `shared.user.gender.other`,
        }
    },
    dashboardTabs: {
        home: `shared.dashboardTabs.home`,
        elabel: `shared.dashboardTabs.elabel`,
        wines: `shared.dashboardTabs.wines`,
        dispenser: `shared.dashboardTabs.dispenser`,
        sellerPanel: `shared.dashboardTabs.sellerPanel`,

        shop: `shared.dashboardTabs.shop`,
        company: `shared.dashboardTabs.company`,
        profile: `shared.dashboardTabs.profile`,
    },
    popupMessages: {
        error: {
            generic: `shared.popupMessages.error.generic`,
            emailNotFound: `shared.popupMessages.error.emailNotFound`,
            codeMismatch: `shared.popupMessages.error.codeMismatch`,
            createVintage: `shared.popupMessages.error.createVintage`,
            notCantinaCompany: `shared.popupMessages.error.notCantinaCompany`,
            wineryWronglySelected: `shared.popupMessages.error.wineryWronglySelected`,
        },
        success: {
            companyCreated: `shared.popupMessages.success.companyCreated`,
            requestSent: `shared.popupMessages.success.requestSent`,
            addressCreated: `shared.popupMessages.success.addressCreated`,
            dataUpdated: `shared.popupMessages.success.dataUpdated`,
            accessDisabled: `shared.popupMessages.success.accessDisabled`,
            accessDenied: `shared.popupMessages.success.accessDenied`,
            companyAccessDisabled: `shared.popupMessages.success.companyAccessDisabled`,
            companyAccessDenied: `shared.popupMessages.success.companyAccessDenied`,
            userInvited: `shared.popupMessages.success.userInvited`,
            userAccessAccepted: `shared.popupMessages.success.userAccessAccepted`,
            companyAccessAccepted: `shared.popupMessages.success.companyAccessAccepted`,
            vintageCreated: `shared.popupMessages.success.vintageCreated`,
            recycleModelAdded: `shared.popupMessages.success.recycleModelAdded`,
            passwordUpdated: `shared.popupMessages.success.passwordUpdated`,
            requestDeleted: `shared.popupMessages.success.requestDeleted`,
        },
        warning: {
            fileFormatNotValid: `shared.popupMessages.warning.fileFormatNotValid`,
            compileMandatoryFields: `shared.popupMessages.warning.compileMandatoryFields`,
            passwordMismatch: `shared.popupMessages.warning.passwordMismatch`,
            imageSize: `shared.popupMessages.warning.imageSize`,
            formNotValid: `shared.popupMessages.warning.formNotValid`,
            verifyCompanyToProceed: `shared.popupMessages.warning.verifyCompanyToProceed`,
            cantDeleteChassisWithDispenser: `shared.popupMessages.warning.cantDeleteChassisWithDispenser`,
        }
    },
    mainSearchbarSuggestions: {
        companySection: `shared.mainSearchbarSuggestions.companySection`,
        companyUserSection: `shared.mainSearchbarSuggestions.companyUserSection`,
        wineSection: `shared.mainSearchbarSuggestions.wineSection`,
        elabelSection: `shared.mainSearchbarSuggestions.elabelSection`,
        profileSection: `shared.mainSearchbarSuggestions.profileSection`,
        profileCompanySection: `shared.mainSearchbarSuggestions.profileSection`,
    },
    infoText: {
        openedAt: `shared.infoText.openedAt`,
        expiredSince: `shared.infoText.expiredSince`,
        expiredInDays: `shared.infoText.expiredInDays`,
    },
    general: {
        bottle: `shared.general.bottle`,
        bottles: `shared.general.bottles`,
        smartbag: `shared.general.smartbag`,
        glass: `shared.general.glass`,
        jug: `shared.general.jug`,
        search: `shared.general.search`,
    },
    states: {
        active: `shared.states.active`,
        inactive: `shared.states.inactive`,
    }
}