import { UserSession } from "-albicchiere-types/lib/base/user";
import { ISmartBag } from "-albicchiere-types/lib/cantina/cantina";
import { IElabel, IElabelBatch, IElabelBatchIngredient } from "-albicchiere-types/lib/elabel/elabel";
import { IElabelSetting } from "-albicchiere-types/lib/elabel/elabelSetting";
import { IWine, IWineVintage } from "-albicchiere-types/lib/wine/wine";
import { AlbiOption, TabSectionType } from "albi-ui/dist/albi-ui-library";

export type ResolvedSmartbagType = (ISmartBag & { vintage: IWineVintage & { wine: IWine } });

export type ResolvedElabelType = Omit<IElabel, 'certifications' | 'packaging' | 'warnings'> & {
    certifications: (IElabelSetting['certifications'][number] & { number?: string, date?: string })[],
    model: IElabelSetting['wineContainerModels'][number],
    packaging: {
        packagingMaterial: IElabelSetting['packagingMaterials'][number],
        packagingComponent: IElabelSetting['packagingComponents'][number]
    }[]
    ,
    vintage: IWineVintage,
    warnings: IElabelSetting['warnings']
};

export type ResolvedElabelBatchType = Omit<IElabelBatch, 'ingredients'> & {
    ingredients: (Omit<IElabelBatchIngredient, 'ingredient'> & {
        ingredient: Omit<IElabelSetting['ingredients'][number], 'category'> & {
            category: IElabelSetting['categories'][number]
        },
    })[]
}

export type AlbiTableColumnInputType = {
    key: string,
    name: string,
    cellSize?: 'small' | 'regular' | 'large'
}

export type ResolvedUserAccessType = UserSession['companyAccesses'][number] & { updatedAt?: string };

export const RELEASE_NUMBER = '0.1.23';

export const LANGUAGES_OPTIONS = [
    { key: "it", label: "Italian" },
    { key: "en", label: "English" },
    { key: "bg", label: "Bulgarian" },
    { key: "cs", label: "Czech" },
    { key: "hr", label: "Croatian" },
    { key: "da", label: "Danish" },
    { key: "et", label: "Estonian" },
    { key: "fi", label: "Finnish" },
    { key: "fr", label: "French" },
    { key: "el", label: "Greek" },
    { key: "ga", label: "Irish" },
    { key: "lv", label: "Latvian" },
    { key: "lt", label: "Lithuanian" },
    { key: "mt", label: "Maltese" },
    { key: "nl", label: "Dutch" },
    { key: "pl", label: "Polish" },
    { key: "pt", label: "Portuguese" },
    { key: "ro", label: "Romanian" },
    { key: "sk", label: "Slovak" },
    { key: "sl", label: "Slovenian" },
    { key: "es", label: "Spanish" },
    { key: "de", label: "German" },
    { key: "sv", label: "Swedish" },
    { key: "hu", label: "Hungarian" },
];

export const PRODUCTION_INFO_OPERATORS: AlbiOption[] = [
    { key: 'bottler', label: 'Imbottigliatore' },
    { key: 'importer', label: 'Importatore' },
    { key: 'producer', label: 'Produttore' },
]

export const LIST_OF_EU_COUNTRIES = [
    'AT',
    'BE',
    'BG',
    'CY',
    'HR',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'CZ',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'HU',
];


export const EXAMPLE_VERTICAL_TABS: TabSectionType[] = [
    {
        items: [
            {
                name: 'home',
                iconName: 'menu.home',
                navigationUrl: 'dashboard',
                disabled: true,
            },
            {
                name: 'eLabel',
                iconName: 'stats.statsLabel',
                navigationUrl: 'dashboard/elabel',
                disabled: true,
            },
            {
                name: 'Wines',
                iconName: 'wine.grapeVariety',
                navigationUrl: 'dashboard/wines',
                disabled: true,
            },
        ]
    }, {
        isBottomSection: true,
        items: [
            {
                name: 'Shop',
                iconName: 'star',
                disabled: true,
            },
            {
                name: 'Company',
                iconName: 'legal.businessAccount',
                navigationUrl: 'dashboard/company',
                disabled: true,
            },
            {
                name: 'Profilo',
                iconName: 'menu.profile',
                navigationUrl: 'profile/info',
            }
        ]
    }
];


export const MAIN_BAR_SUGGESTIONS = [
    {
        path: 'dashboard/company',
        name: 'companySection',
        tags: ['company', 'azienda', 'winery', 'cantina']
    },
    {
        path: 'dashboard/company/users',
        name: 'companyUserSection',
        tags: ['company', 'azienda', 'winery', 'cantina', 'users', 'utenti', 'persone']
    },
    {
        path: 'dashboard/wines',
        name: 'wineSection',
        tags: ['wine', 'vini', 'annate', 'vintages', 'wines']
    },
    {
        path: 'dashboard/elabel',
        name: 'elabelSection',
        tags: ['elabel', 'etichette', 'label', 'labels']
    },
    {
        path: 'profile/info',
        name: 'profileSection',
        tags: ['profile', 'profilo', 'user', 'utente']
    },
    {
        path: 'profile/company',
        name: 'profileCompanySection',
        tags: ['profile', 'profilo', 'user', 'utente', 'company', 'azienda', 'winery', 'cantina']
    },
]