import { Routes } from "@angular/router"
import { AddressFormComponentComponent } from "@dashboard-components/address-form-component/address-form-component.component"
import { AlbiListItem } from "@dashboard-components/albi-list-item/albi-list-item.component"
import { AppPageLayoutComponent } from "@dashboard-components/app-page-layout/app-page-layout.component"
import { AppTopBarComponent } from "@dashboard-components/app-top-bar/app-top-bar.component"
import { ColorPickerComponent } from "@dashboard-components/color-picker/color-picker.component"
import { CompanyCreationFormComponent } from "@dashboard-components/company-creation-form/company-creation-form.component"
import { GlassCounterComponent } from "@dashboard-components/glass-counter/glass-counter.component"
import { ImpressumBoxComponent } from "@dashboard-components/impressum-box/impressum-box.component"
import { PageLoaderComponent } from "@dashboard-components/page-loader/page-loader.component"
import { SelectEnterpriseAccessComponent } from "@dashboard-components/select-enterprise-access/select-enterpriseAccess.component"
import { UserRoleFormComponent } from "@dashboard-components/user-role-form/user-role-form.component"
import { VintageCreationFormComponent } from "@dashboard-components/vintage-creation-form/vintage-creation-form.component"
import { WineCreationFormComponent } from "@dashboard-components/wine-creation-form/wine-creation-form.component"
import { CompanyGuard } from "src/dashboards/dashboard/guards/company.guard"
import { DispenserAlertTranslatePipe } from "src/dashboards/dispenser/pipes/dispenserAlerTranslate.pipe"
import { CastObjectToAlbiOptionPipe } from "src/dashboards/pipes/albiOptionCast.pipe"
import { ImageUrlWithLastUpdatePipe } from "src/dashboards/pipes/ImageUrlWithLastUpdate.pipe"
import { ResolveChipsArrayPipe } from "src/dashboards/pipes/resolveChipsArray.pipe"
import { ResolveCountryCodePipe } from "src/dashboards/pipes/resolveCountryCode.pipe"
import { ResolveDateStringPipe } from "src/dashboards/pipes/resolveDate.pipe"
import { ResolveLocaleFieldPipe } from "src/dashboards/pipes/resolveLocaleFields.pipe"
import { ResolveRolePipe } from "src/dashboards/pipes/resolveRole.pipe"
import { ResolveVintageName } from "src/dashboards/pipes/resolveVintageName.pipe"
import { TooltipDirective } from "src/directives/tooltip.directive"
import { AuthenticationComponent } from "../authentication/authentication.component"
import { AuthGuard } from "../authentication/guards/auth.guard"
import { DashboardComponent } from "../dashboards/dashboard/dashboard.component"
import { NotFoundComponent } from "../errros/not-found/not-found.component"
import { HomeComponent } from "../home/home.component"

export const DECLARATIONS = [
    AuthenticationComponent,
    HomeComponent,
    DashboardComponent,
    NotFoundComponent,
    // OnboardingRouteComponent,
]

export const EXPORT_DECLARATIONS = [
    AlbiListItem,
    PageLoaderComponent,
    ResolveRolePipe,
    SelectEnterpriseAccessComponent,
    VintageCreationFormComponent,
    WineCreationFormComponent,
    CompanyCreationFormComponent,
    AddressFormComponentComponent,
    ResolveLocaleFieldPipe,
    ColorPickerComponent,
    ImpressumBoxComponent,
    ResolveChipsArrayPipe,
    ResolveDateStringPipe,
    ResolveVintageName,
    AppTopBarComponent,
    AppPageLayoutComponent,
    ResolveCountryCodePipe,
    UserRoleFormComponent,
    ImageUrlWithLastUpdatePipe,
    TooltipDirective,
    CastObjectToAlbiOptionPipe,
    GlassCounterComponent,
    DispenserAlertTranslatePipe,
]

export const ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'authentication',
        component: AuthenticationComponent,
        children: [
            {
                path: 'login',
                loadChildren: () => import('./../authentication/login/login.module').then(m => m.LoginModule)
            },
            {
                path: 'registration',
                loadChildren: () => import('./../authentication/registration/registration.module').then(m => m.RegistrationModule)
            }, {
                path: 'reset_password',
                loadChildren: () => import('../authentication/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
            }
        ]
    },
    /*{
        path: 'onboarding',
        component: OnboardingRouteComponent,
        providers: [
            CompanyCreateFormService,
            RequestCodeService
        ],
        children: [
            {
                path: 'connect',
                loadChildren: () => import('./../onboarding/connect/onboarding-connect.module').then(m => m.OnboardingModule),
            },
            {
                path: 'create',
                loadChildren: () => import('./../onboarding/create-company/create-company.module').then(m => m.CreateCompanyModule)
            },
            {
                path: 'pending_verification',
                loadChildren: () => import('./../onboarding/pending-verification/pending-verification.module').then(m => m.PendingVerificationModule)
            }
        ]
    },*/
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard, CompanyGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./../dashboards/home-page/home-page.module').then(m => m.HomePageModule)
            },
            {
                path: 'elabel',
                loadChildren: () => import('./../dashboards/elabel/elabel.module').then(m => m.ElabelComponentModule)
            },
            {
                path: 'wines',
                loadChildren: () => import('./../dashboards/wine/wine.module').then(m => m.WineModule)
            },
            {
                path: 'company',
                loadChildren: () => import('./../dashboards/company/company.module').then(m => m.CompanyModule)
            },
            {
                path: 'dispenser',
                loadChildren: () => import('./../dashboards/dispenser/dispenser.module').then(m => m.DispenserModule)
            }
        ]
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./../profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'verify',//http://localhost:4200/verify?token=pippo
        loadChildren: () => import('./../email-verification-page/email-verification-page.module').then(m => m.EmailVerificationPageModule),
    },
    {
        path: 'verify-invitation',//http://localhost:4200/verify-invitation?token=pippo
        canActivate: [AuthGuard],
        loadChildren: () => import('./../company-invite-verification/company-invite-verification.module').then(m => m.CompanyInviteVerificationModule),
    },
    {
        path: 'company-pending-verification',
        loadChildren: () => import('./../company-pending-verification/company-pending-verification.module').then(m => m.CompanyPendingVerificationModule),
    },
    {
        path: 'company-connect',
        loadChildren: () => import('./../company-connect/company-connect.module').then(m => m.CompanyConnectModule),
    },
    {
        path: 'company-create',
        loadChildren: () => import('./../company-create/company-create.module').then(m => m.CompanyCreateModule),
    },
    {
        path: 'not-found',
        component: NotFoundComponent,
        pathMatch: 'full'
    },
    {
        path: '',
        redirectTo: '/not-found',
        pathMatch: 'full'
    }
]